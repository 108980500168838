//@ts-nocheck
import 'element-internals-polyfill';
import { LitElement, html, css } from 'lit';
import routerMixin from './router/router-mixin.js';
import './styles/fonts/Lato-Bold.ttf';
import './styles/fonts/Lato-Regular.ttf';
import { routes } from './router/routes.js';
import { go } from './router/router-mixin.js';
import globalStyles from './styles/global-styles.js';
import { clearSession, getSessionExpiration, getSessionUser, sessionUserIsAdmin } from './session/session.js';
import { loginIcon, leftArrowIcon } from './styles/icons/action-icons.js';
import { houseIconOutline } from './styles/icons/object-icons.js';
import Globals from './Globals.js';
import AppEvents from './app-events.js';
import AppHeaderElement from './shared/components/app-header.js';
import GenericAppDialog from './shared/components/generic-app-dialog.js';
window.Globals = new Globals();
class AppEnterElement extends routerMixin(LitElement) {
    constructor() {
        super();
        this.dialogOpen = false;
        this.message = '';
    }
    /** @param {Event} e */
    handleBackClick(e) {
        e.preventDefault();
        if (this.backArrowOverride)
            return go(this.backArrowOverride.path, this.backArrowOverride.params);
        if (this.backArrowLocation)
            return go(this.backArrowLocation);
        window.history.back();
    }
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener(AppEvents.SET_BACK_ARROW, (/** @type {Event & {detail: {path: string, params: Object}}} */ e) => (this.backArrowOverride = e.detail));
        window.addEventListener(AppEvents.GENERIC_DIALOG, () => {
            const appDialog = this.shadowRoot.querySelector('generic-app-dialog');
            if (appDialog) {
                this.dialogOpen = !this.dialogOpen;
                this.message = event.detail.message;
                this.requestUpdate();
            }
            else
                console.log('no app dialog');
        });
        this.startSessionInterval();
        this.handleUpdate();
        this.isMobile = window.innerWidth < 768;
    }
    handleUpdate() {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .getRegistration()
                .then((registration) => {
                if (registration) {
                    registration.update();
                }
                else {
                    console.error('Service worker registration not found.');
                }
            })
                .catch((error) => {
                console.error(`Error checking for service worker registration: ${error}`);
            });
        }
    }
    startSessionInterval() {
        const CHECK_INTERVAL = 60 * 1000;
        const checkSession = () => {
            const sessionExpiration = getSessionExpiration();
            if (!sessionExpiration)
                return;
            const remaining = new Date(sessionExpiration).getTime() - new Date().getTime();
            if (remaining <= 0) {
                clearSession();
                clearInterval(this.sessionInterval);
                this.dispatchEvent(new CustomEvent(AppEvents.GENERIC_DIALOG, {
                    detail: { message: 'Your session has expired, please login again' },
                }));
                go(routes.LOGIN.path);
            }
        };
        this.sessionInterval = setInterval(checkSession, CHECK_INTERVAL);
        checkSession();
    }
    handleLogoClick() {
        if (window.location.pathname.includes(routes.MAP.path)) {
            go(routes.DASHBOARD.path);
        }
    }
    render() {
        const homeRoute = sessionUserIsAdmin() ? routes.ADMIN.path : routes.LANDING_PAGE.path;
        this.handleUpdate();
        this.isMobile = window.innerWidth < 768;
        return html `
      ${this.showHeader ? html ` <app-header></app-header> ` : null}
      <slot style="${this.isMobile ? '' : 'margin-left: var(--desktop-nav-width);'}" ?header=${this.showHeader}></slot>
      <generic-app-dialog style="display: ${this.dialogOpen ? 'block' : 'none'}"
        ><p slot="body">${this.message}</p></generic-app-dialog
      >
    `;
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        clearInterval(this.sessionInterval);
    }
}
AppEnterElement.styles = [
    globalStyles,
    css `
      :host {
        padding: 0px;
      }

      slot {
        position: fixed;
        height: calc(100% - 200px);
        overflow-y: scroll;
      }

      slot[header] {
        width: 100%;
        display: block;
        margin-top: var(--app-header-height);
      }
      header {
        display: flex;
        position: fixed;
        top: 0px;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: var(--app-primary-color);
      }
      .header-icons {
        height: calc(var(--app-header-height) * 0.7);
        z-index: 7000;
        display: flex;
        align-items: center;
      }
      .home {
        right: 15px;
      }
      a {
        cursor: pointer;
      }
      a > svg {
        width: 25px;
        height: 25px;
      }
      .loginButton {
        display: flex;
        background-color: transparent;
        border-color: white;
        margin: 0px;
        transform: translate(-10px, 0px);
      }
      .navIcon {
        display: flex;
        color: white;
        padding: 25px;
      }
    `,
];
customElements.define('app-enter', AppEnterElement);
export default AppEnterElement;
