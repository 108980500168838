var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// @ts-nocheck
import { LitElement, css, html } from 'lit';
import { go } from '../../router/router-mixin.js';
import { routes } from '../../router/routes.js';
import { kulaIcon, kulaIconSecondary, colorBars } from '../../icons/brand-icons.js';
import AppEvents from '../../app-events.js';
import { findProjectsIcon, dashboardIcon, investmentsIcon, adminIcon, reportsIcon } from '../../icons/app-icons.js';
import { getSessionUser } from '../../session/session.js';
import { getAllProjects } from '../../services/project-service.js';
import { sandboxIcon } from '../../styles/icons/dashboard-icons.js';
class AppHeaderElement extends LitElement {
    constructor() {
        super();
        this.isMobile = window.innerWidth < 768;
        this.user = getSessionUser();
        this.isAdmin = false;
        this.isProjectAdmin = false;
        this.isProjectOwner = false;
        this.menuOptions = [
            { label: 'Dashboard', path: routes.DASHBOARD.path, icon: dashboardIcon, disable: false },
            {
                label: 'Reports',
                path: routes.REPORTS.path,
                icon: reportsIcon,
                disable: false,
            },
            { label: 'Find Projects', path: routes.FIND_PROJECTS.path, icon: findProjectsIcon, disable: false },
            {
                label: 'My Investments',
                path: routes.MY_INVESTMENTS.path,
                icon: investmentsIcon,
                disable: !this.user,
            },
            { label: 'Admin', path: routes.ADMIN.path, icon: adminIcon, disable: !this.isAdmin || !this.user },
        ];
    }
    connectedCallback() {
        const _super = Object.create(null, {
            connectedCallback: { get: () => super.connectedCallback }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.connectedCallback.call(this);
            window.addEventListener(AppEvents.ROUTE_CHANGE, () => this.requestUpdate());
            yield this.getPermissions();
            this.updateMenuOptions();
            const projects = (yield getAllProjects()).filter((project) => project.ownerIds.includes(this.user.userId));
        });
    }
    getPermissions() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const userPermissions = ((_a = this.user) === null || _a === void 0 ? void 0 : _a.permissions.Permissions) || [];
            const roles = userPermissions.map((permission) => permission.role);
            this.isAdmin = roles.includes('admin');
            this.isProjectOwner = roles.includes('project_owner');
            this.isProjectAdmin = roles.includes('project_admin');
            this.requestUpdate();
        });
    }
    updateMenuOptions() {
        this.menuOptions = this.menuOptions.map((option) => (Object.assign(Object.assign({}, option), { disable: this.calculateDisableState(option) })));
        this.requestUpdate();
    }
    calculateDisableState(option) {
        switch (option.label) {
            case 'Reports':
                return !(this.isAdmin || this.isProjectOwner || this.isProjectAdmin) || !this.user;
            case 'My Investments':
                return !this.user;
            case 'Admin':
                return !this.isAdmin || !this.user;
            default:
                return option.disable;
        }
    }
    render() {
        return this.isMobile ? this.renderMobileNav() : this.renderDesktopNav();
    }
    renderMobileNav() {
        return html `
      <div class="mobile-nav">
        ${this.menuOptions.map((option) => html ` <h3
              style="fill: ${window.location.pathname == option.path
            ? 'var(--app-secondary-color)'
            : '#787773'}; display: ${option.disable ? 'none' : ''}"
              @click=${() => go(option.path)}
            >
              ${option.icon}
            </h3>`)}
      </div>
    `;
    }
    renderDesktopNav() {
        return html `
      <div class="desktop-nav">
        <span @click=${() => go(routes.LANDING_PAGE.path)} class="header-icon">${kulaIconSecondary}</span>
        <div class="options">
          ${this.menuOptions.map((option) => html ` <div class="option-wrap" style="display: ${option.disable ? 'none' : ''}">
                <span
                  style="fill: ${window.location.pathname == option.path ? 'var(--app-secondary-color)' : '#787773'}"
                  >${option.icon}</span
                >
                <h3
                  style="color: ${window.location.pathname == option.path ? 'var(--app-secondary-color)' : ''}"
                  @click=${() => go(option.path)}
                >
                  ${option.label}
                </h3>
              </div>`)}
        </div>
      </div>
    `;
    }
}
AppHeaderElement.properties = {
    user: { type: Object },
    isMobile: { type: Boolean },
    menuOptions: { type: Array },
    isAdmin: { type: Boolean },
    isProjectOwner: { type: Boolean },
    isProjectAdmin: { type: Boolean },
};
AppHeaderElement.styles = css `
    h3 {
      cursor: pointer;
      color: var(--Grey, #787773);
      /* Subtitle */
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      padding: 0;
      transition: color 0.1s;
    }
    h3:hover {
      color: var(--app-tertiary-color);
      transition: color 0.1s;
    }

    .header-icon {
      margin-top: 16px;
      margin-left: 16px;
    }
    .mobile-nav {
      position: fixed;
      bottom: 0;
      width: 100vw;
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      gap: 16px;
      border-top: 2px solid var(--app-grey-2);
      background-color: var(--app-white);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    }
    .desktop-nav {
      position: fixed;
      display: flex;
      width: 220px;
      height: 100%;
      flex-direction: column;
      align-items: left;
      justify-content: flex-start;
      gap: 24px;
      flex-shrink: 0;
      background-color: var(--app-white);
      transition: transform 0.3s ease-in-out;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    }

    .option-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      padding: 16px;
    }
    .options {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  `;
export default AppHeaderElement;
customElements.define('app-header', AppHeaderElement);
