var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getSessionUser } from '../session/session.js';
import { apiFetch } from './api-fetch.js';
/**
 * @param {string} name
 * @param {string} description
 * @param {Object} ownerIds
 * @param {string} stripeApiKey
 * @param {string} stripePublicKey
 * @param {string} stripeWebhookSecret
 * @param {string} stripe_webhook_id
 * @param {string} zip
 * @param {string} country
 * @param {string} address
 * @param {string} address2
 * @param {string} state
 * @param {string} email
 * @param {Boolean} active
 */
export function createProject(name, description, ownerIds, stripeApiKey, stripePublicKey, stripeWebhookSecret, stripe_webhook_id, zip, country, address, address2, state, email, active) {
    return __awaiter(this, void 0, void 0, function* () {
        const newProject = {
            name,
            description,
            ownerIDs: ownerIds,
            stripeApiKey,
            stripePublicKey,
            stripeWebhookSecret,
            stripe_webhook_id,
            zip,
            country,
            address,
            address2,
            state,
            email,
            active,
        };
        return apiFetch('/v1/projects/create', 'POST', newProject);
    });
}
/**
 * @param {string} projectID
 * @param {string} name
 * @param {string} description
 * @param {Object} ownerIds
 * @param {string} stripeApiKey
 * @param {string} stripePublicKey
 * @param {string} stripeWebhookSecret
 * @param {string} stripe_webhook_id
 * @param {string} zip
 * @param {string} country
 * @param {string} address
 * @param {string} address2
 * @param {string} state
 * @param {string} city
 * @param {string} email
 * @param {Boolean} active
 */
export function updateProject(projectID, name, description, ownerIds, stripeApiKey, stripePublicKey, stripeWebhookSecret, stripe_webhook_id, zip, country, address, address2, state, city, email, active) {
    return __awaiter(this, void 0, void 0, function* () {
        const updatedProject = {
            id: projectID,
            name,
            description,
            ownerIDs: ownerIds,
            stripeApiKey,
            stripePublicKey,
            stripeWebhookSecret,
            stripe_webhook_id,
            zip,
            country,
            address,
            address2,
            state,
            city,
            email,
            active,
        };
        const response = yield apiFetch(`/v1/projects/update`, 'PUT', updatedProject);
        return response;
    });
}
export function getAllProjects() {
    return __awaiter(this, void 0, void 0, function* () {
        return apiFetch('/v1/projects/getAll', 'GET').then((r) => r.json());
    });
}
/**
 * @param {string} projectID
 */
export function deleteProject(projectID) {
    return __awaiter(this, void 0, void 0, function* () {
        return apiFetch(`/v1/projects/delete?id=${projectID}`, 'DELETE');
    });
}
export function getUserProject() {
    return __awaiter(this, void 0, void 0, function* () {
        return apiFetch(`/v2/projects`, 'GET');
    });
}
