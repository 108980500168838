import { LitElement, html, css } from 'lit';
import { xIcon } from '../../styles/icons/action-icons.js';
import AppEvents from '../../app-events.js';
import globalStyles from '../../styles/global-styles.js';
class GenericAppDialog extends LitElement {
    constructor() {
        super();
        this.title = '';
        this.large = false;
        this.showButtons = true;
        this.logoHeader = false;
    }
    connectedCallback() {
        super.connectedCallback();
        window.document.body.style.overflow = 'hidden';
    }
    disconnectedCallback() {
        super.disconnectedCallback();
        window.document.body.style.overflow = 'auto';
    }
    close() {
        this.dispatchEvent(new CustomEvent(AppEvents.GENERIC_DIALOG, {
            bubbles: true,
            composed: true,
            detail: {},
        }));
    }
    render() {
        return html `
      <div class="wrapper">
        <div class=${this.large ? 'content large' : 'content'}>
          <header>
            ${this.logoHeader
            ? html ` <header>
                  <div class="logo-wrap">
                    <img class="logo" src="/images/logo.png" alt="Logo" />
                  </div>
                </header>`
            : null}
            ${this.title ? html ` <h2>${this.title}</h2> ` : html ` <slot name="header"> </slot> `}
          </header>
          <div class="dialog-body">
            <slot name="body"> </slot>
            ${this.showButtons ? this.renderButtons() : ''}
          </div>
        </div>
      </div>
      <div class="dialog-bg" @click=${this.close}></div>
    `;
    }
    renderButtons() {
        return html ` <button class="outline" @click=${this.close}>Close</button> `;
    }
}
GenericAppDialog.properties = {
    title: { type: String },
    large: { type: Boolean },
    closeButton: { type: Boolean },
    logoHeader: { type: Boolean },
};
GenericAppDialog.styles = css `
    ${globalStyles}
    :host {
      --header-height: 6rem;
    }
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
    .wrapper {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content {
      background: white;
      width: var(--app-dialog-card-width, calc(100% - 1rem));
      max-height: calc(100% - 1rem);
      max-width: 36rem;
      border-radius: var(--app-border-radius);
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      color: var(--primary);
      z-index: 5500;
      position: relative;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    }
    .dialog-body {
      padding: var(--app-dialog-article-padding, 1rem);
    }
    header {
      width: 100%;
      padding: var(--app-dialog-header-padding, 1rem);
      display: flex;
      justify-content: var(--app-dialog-header-justify-content, space-between);
      border-bottom: var(--app-dialog-header-border, 1px solid var(--dusk-light));
    }
    .logo-wrap {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .logo {
      margin-top: 32px;
      height: calc(var(--header-height) * 1);
    }
    .close-button {
      background: unset;
      border: none;
      padding: 0;
      cursor: pointer;
      border-radius: var(--border-radius-sharp);
      color: var(--icon-fill);
    }
    header h2 {
      font-family: var(--app-dialog-title-font-family, var(--headline-font));
      margin: 0;
      font-size: var(--app-dialog-header-font-size, 1rem);
      line-height: 1em;
    }
    .close-button:hover {
      background-color: var(--dusk-light);
    }
    .dialog-bg {
      position: fixed;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
      z-index: 5000;
    }

    .dialog-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media only screen and (min-width: 640px) {
      .dialog-body {
        padding: var(--app-dialog-article-padding-640, 2rem);
      }
      header {
        padding: var(--app-dialog-header-padding-640, 1.5rem 2rem);
      }
      .content {
        height: auto;
      }
      header h2 {
        font-size: var(--app-dialog-header-font-size-640, 1.2rem);
      }
      .content.large {
        max-width: 100%;
        width: calc(100% - 3rem);
        max-width: 1280px;
        max-height: calc(100% - 3rem);
      }
    }
    @media only screen and (min-width: 768px) {
      .content.large {
        width: calc(100% - 4rem);
        max-height: calc(100% - 4rem);
      }
    }
    @media only screen and (min-width: 1024px) {
      .content.large {
        width: 90vw;
        max-height: calc(100% - 4rem);
      }
    }
  `;
export default GenericAppDialog;
customElements.define('generic-app-dialog', GenericAppDialog);
