import { css } from 'lit';
const inputGlobalStyles = css `
  input:not([type='checkbox']),
  textarea {
    display: block;
    border-radius: var(--small-border-radius);
    border: var(--small-border);
    padding: 16px;
    font-size: 16px;
    min-width: 250px;
    box-sizing: border-box;
    outline: 2px solid var(--app-grey-2);
    width: 100%;
  }
  input:focus {
    outline: 2px solid var(--app-grey);
  }

  /* Toggle Switch Styles */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: var(--app-primary-color);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px var(--app-primary-color);
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  @media only screen and (max-width: 1024px) {
  }

  @media only screen and (max-width: 768px) {
  }

  @media only screen and (max-width: 480px) {
  }
`;
export default inputGlobalStyles;
