var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getUserToken } from '../session/session.js';
const DEFAULT_API = process.env.API_URL;
/** @type {HttpMethods} */
export const Methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
};
/**
 * @param {string} path
 * @param {ApiFetchOptions} method
 * @param {Object} [body]
 * @returns {Promise<Response>}
 */
export function apiFetch(path, method, body = null, API = DEFAULT_API) {
    return __awaiter(this, void 0, void 0, function* () {
        /** @type {RequestInit & {headers: HeadersInit & {Authorization: string}}}} */
        const options = {
            method,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${getUserToken() || ''}`,
            },
        };
        if (body)
            options['body'] = JSON.stringify(body);
        const res = yield fetch(API + path, options);
        if (res.ok) {
            return res;
        }
        else {
            throw /** @type {HttpError} */ {
                message: (yield res.json()).message || res.statusText,
                res,
            };
        }
    });
}
