import { css } from 'lit';
import buttonGlobalStyles from './button-global-styles.js';
import inputGlobalStyles from './input-global-styles.js';
import formGlobalStyles from './form-global-styles.js';
import cardGlobalStyles from './card-global-styles.js';
const globalStyles = css `
  ${buttonGlobalStyles}
  ${formGlobalStyles}
  ${inputGlobalStyles}
  ${cardGlobalStyles}
  * {
    font-family: 'Lato', sans-serif;
  }
  .gradient-text {
    background: var(--gradient1, linear-gradient(271deg, #af3331 27.13%, #cb841d 78.12%));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; /* Make the text color transparent */
    -webkit-text-fill-color: transparent; /* For better support in some browsers */
  }
  .title {
    font-family: 'Lato';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
  }
  .label-primary {
    font-family: 'Lato';
    font-weight: 400;
    font-weight: 500;
    font-size: 20px;
  }
  .label-secondary {
    font-family: 'Lato';
    font-weight: 400;
    font-weight: 500;
    font-size: 16px;
  }
  .body-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .center-me {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000;
    font-family: Lato;
    font-size: 33.499px;
    font-style: normal;
    font-weight: 600;
    line-height: 36.545px; /* 109.091% */
    letter-spacing: 0.761px;
  }
  .card {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: flex-start;
    box-sizing: border-box;
    background-color: white;
    margin: auto;
  }
  .card-gallery {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    gap: 32px;
  }
  .background {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: top;
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    padding: 16px;
    padding-bottom: 80px;
    overflow-y: scroll;
    overflow-x: hidden;
    background-image: url('/images/pattern.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
`;
export default globalStyles;
